import { Link, graphql } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';

import Loadable from 'react-loadable';
import React, { useState } from 'react';
import BodyClassName from 'react-body-classname';

import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import ReviewModal from '../components/Testimonial/ReviewModal';
import iconMessageSquare from '../images/icon-message-square.svg';
import DefaultLayoutComponent from '../modules/layouts/DefaultLayoutComponent';
import TestimonialMediaItem from '../components/Testimonial/TestimonialMediaItem';
import TestimonialDetailPopup from '../components/Testimonial/TestimonialDetailPopup';

const loader = () => <div>Loading...</div>;

const LoadableCarousel = Loadable({
  loader: () => import('../components/Testimonial/TestimonialCarousel'),
  loading: loader,
});

const TestimonialPage = (props) => {
  const {
    data: {
      bgContact,
      testimonials,
      videoTestimonials: { nodes: videoTestimonials },
    },
  } = props;

  const [selectedTestimonial, setSelectedTestimonial] = useState();
  const [reviewModalOpened, showReviewModal] = useState(false);

  return (
    <BodyClassName className="body-light page-testimonial">
      <DefaultLayoutComponent title="Testimonial">
        <div className="site-main">
          <BackgroundImage
            className="page-banner bgImage"
            fluid={bgContact.childImageSharp.fluid}
            preserveStackingContext
          >
            <div className="overlay" />
            <div className="wrapper wrapper-xl pd-x-md">
              <div className="pd-x-md pb-container">
                <h3 className="pb-title">Testimonials</h3>
              </div>
              <div className="wrapper wrapper-lg pd-x-sm">
                {videoTestimonials && (
                  <div className="layout-media layout-media-watch">
                    <div className="layout-inner layout-2 d-flex">
                      {videoTestimonials.slice(0, 2).map((testimonial) => {
                        return (
                          <div className="cols item-1 cols-1" key={testimonial?.nodes?.data?.title[0]?.text}>
                            <TestimonialMediaItem row="row1" testimonial={testimonial} />
                          </div>
                        );
                      })}
                    </div>
                    <div className="layout-inner layout-2x2 d-flex">
                      <div className="cols item-2 d-flex">
                        {videoTestimonials.slice(2, 6).map((testimonial) => (
                          <div className="cols-inner cols-inner-1" key={testimonial?.nodes?.data?.title[0]?.text}>
                            <TestimonialMediaItem row="row2" testimonial={testimonial} />
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </BackgroundImage>
          {!!selectedTestimonial && (
            <TestimonialDetailPopup testimonial={selectedTestimonial} toggleModal={setSelectedTestimonial} />
          )}
          <section className="testimonial-container bg-dark">
            <div className="wrapper wrapper-xl pd-x-md">
              <div className="section testimonials">
                <div className="wrapper wrapper-lg ">
                  <div className="carousel pd-x-sm">
                    <LoadableCarousel testimonials={testimonials} openTestimonialPopup={setSelectedTestimonial} />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div className="section testimonial-review bg-dark pd-t-0">
            <div className="wrapper wrapper-xl pd-x-md">
              <h3>Have you been impacted by Lineage?</h3>
              <div className="explore-link pd-x-md">
                <div className="btn btn-md btn-primary-ii btn-review" onClick={() => showReviewModal(true)}>
                  <img src={iconMessageSquare} alt="" />
                  <span>Send us a Testimonial</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ReviewModal showModal={reviewModalOpened} closeModal={showReviewModal} />
      </DefaultLayoutComponent>
    </BodyClassName>
  );
};

export const query = graphql`
  {
    bgContact: file(relativePath: { eq: "bg-contact.jpg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1280) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    testimonials: allPrismicTestimonials {
      nodes {
        data {
          title {
            text
          }
          position {
            text
          }
          featuredImage: featured_image {
            url
          }
          description {
            text
          }
          address {
            text
          }
        }
      }
    }

    videoTestimonials: allPrismicEpisodes(
      filter: { data: { categories: { elemMatch: { episodes_category: { slug: { eq: "testimonials" } } } } } }
      sort: { fields: data___youtube_id___text }
    ) {
      nodes {
        data {
          title {
            text
          }
          description {
            text
          }
          videoDuration: video_duration {
            text
          }
          youtubeId: youtube_id {
            text
          }
          thumbnailImage: thumbnail_image {
            url
          }
        }
        slugs
      }
    }
  }
`;

export default TestimonialPage;
