import React, { useState } from 'react';

const token = process.env.GATSBY_SLACK_TOKKEN;

export default function ReviewModal({ showModal, closeModal }) {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [file, setFile] = useState('');
  const [description, setDescription] = useState('');
  const [error, setError] = useState({ userName: '', email: '', description: '' });
  const imageInputRef = React.useRef();
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const resetForm = () => {
    setName('');
    setEmail('');
    setFile('');
    imageInputRef.current.value = '';
    setDescription('');
  };
  const onSubmit = async (e) => {
    e.preventDefault();

    if (!name || !email || !description) {
      const error = {
        userName: name ? '' : 'Name is Required',
        email: email ? '' : 'Email is Required',
        description: description ? '' : 'Review is Required',
      };
      setError(error);
      return;
    }

    const text = `Testimonial sent by ${name}\n Email Address : ${email} \n Review:${description}`;
    let url = file ? process.env.GATSBY_TESTIMONIAL_SLACK_UPLOAD_FILE : process.env.GATSBY_TESTIMONIAL_SLACK_URL;
    const formData = new FormData();

    formData.append('channels', process.env.GATSBY_SLACK_CHANNEL_ID);
    formData.append('token', token);
    formData.append('initial_comment', text);
    formData.append('file', file);
    const data = {
      channel: process.env.GATSBY_SLACK_CHANNEL_ID,
      text: text,
    };

    var opts = {
      method: 'POST',
      body: file ? formData : JSON.stringify(data),
    };

    if (!file) {
      opts.headers = {
        Accept: 'application/json, text/plain, */*',
        'Content-type': 'application/x-www-form-urlencoded',
      };
    }
    setIsSubmitting(true);

    fetch(url, opts)
      .then((response) => response)
      .then((data) => {
        if (data?.ok || data?.status === 200) {
          closeModal(false);
          resetForm();
        } else {
          closeModal(true);
        }
        setIsSubmitting(false);
      })
      .catch((error) => {
        setIsSubmitting(false);
        closeModal(false);
      });
  };

  const onValueChange = (e, setValue) => {
    let isValidEmail = true;
    if (e.target.name === 'email') {
      isValidEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(e.target.value);
    }
    const newError = { ...error, [e.target.name]: '', email: isValidEmail ? '' : 'Email is Invalid' };
    setError(newError);
    setValue(e.target.value);
  };

  return (
    <div className={`popup popup-center-xy popup-access popup-review ${showModal ? 'active' : ''}`}>
      <div className="wrapper wrapper-xs center-xy">
        <div className="nav-bars" onClick={() => closeModal(false)}>
          <div></div>
          <div></div>
        </div>
        <div className="card-access">
          <h4>Leave your review</h4>
          <p>Please share with us how Lineage Journey has impacted your life</p>
          <form className="form-access">
            <div className="form-element username">
              <label>Name * </label>
              <input type="text" name="userName" value={name} onChange={(e) => onValueChange(e, setName)} />
              <label className="form-error">{error.userName}</label>
            </div>
            <div className="form-element username">
              <label>Email *</label>
              <input type="email" name="email" value={email} onChange={(e) => onValueChange(e, setEmail)} />
              <label className="form-error">{error.email}</label>
            </div>
            <div className="form-element username">
              <label>Image</label>
              <input
                type="file"
                name="file"
                accept="image/*"
                onChange={(e) => setFile(e.target.files[0])}
                ref={imageInputRef}
              />
            </div>
            <div className="form-element message">
              <label>Review *</label>
              <textarea name="description" value={description} onChange={(e) => onValueChange(e, setDescription)} />
              <label className="form-error">{error.description}</label>
            </div>
            <div className="form-element submit">
              <input
                type="submit"
                value={isSubmitting ? 'Loading' : 'Submit'}
                className="btn btn-md btn-primary-ii btn-submit"
                onClick={onSubmit}
                disabled={isSubmitting}
              />
            </div>
          </form>
        </div>
      </div>
      <div className="popup-overlay" onClick={() => closeModal(false)}></div>
    </div>
  );
}
